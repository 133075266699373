/* Navbar.css */

/* Base styles */


.navbar {

    height: 5rem;
}

.navbar-nav .nav-item .nav-link {
    color: white !important;
    transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-item .nav-link:hover {
    color: #007BFF !important;
}

.dropdown-menu {
    background-color: #240750 !important;
    border: none;
}

.dropdown-menu .dropdown-item {
    color: white !important;
    transition: color 0.3s ease-in-out;
}

.dropdown-menu .dropdown-item:hover {
    color: #007BFF !important;
}

.dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
}

/* Responsive styles */
@media (max-width: 992px) {
    .navbar {
        height: auto;
    }

    .navbar-toggler {
        border: none;
        outline: none;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .navbar-collapse {
        text-align: start;
    }

    .navbar-nav {
        flex-direction: column;
        margin-top: 1rem;
    }

    .nav-item {
        margin-bottom: 1rem;
        padding-left: 5px;
        font-family: 'Courier New', Courier, monospace;
        font-size: 1rem;
        font-weight: 500;
    }

    .dropdown-menu {
        position: static;
        float: none;
    }
}

@media (max-width: 576px) {
    .navbar {
        padding: 1rem;
        background-color: white !important;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 1px 20px 10px #212529;
    }

    .nav-item {
        margin-bottom: 0.5rem;
        display: block;
        width: 6rem;
    }

    .navbar-collapse {
        text-align: start;
        width: 2rem !important;
        margin-top: 0.5rem;
        background-color:#240750;
        border-radius: 8px;
    }
}