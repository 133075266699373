@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.red {
    background-color: #212529;
    height: 100vh;
}

.container {
    width: 100%;
    height: 88vh;
    display: flex;
    align-items: center;
    justify-content: center;

}

.cropper {
    position: relative;
    width: 80vw;
    height: 75vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.09);
    margin-top: 2rem;

}

input[type='radio'] {
    accent-color: #189580;
}

.btn-custom {
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
    background: rgb(73, 73, 224);
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 1rem;
    display: flex;
}

.custom-file-container {
    height: 30rem;
    width: 80rem;
    background-color: white;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../public/assets-cached.jpg");
    background-size: contain;
}

.btn-outline {
    background: #ffffff;
    color: #189580;
    border: 2px solid #189580;
}

.action-btns {
    padding-bottom: 1rem;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.cropped-img {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.aspect-ratios {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    margin: 10px;
}

.custum-inside-container {
    height: 20rem;
    width: 60rem;
    background-color: rgb(73, 72, 72);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
}

.write-text {
    height: 15rem;
    width: 99%;
    background-color: white;
    color: white;
    border-radius: 10px;
    background: transparent;
}

.write-text h1 {
    font-size: 2.5rem;
    font-weight: 400;
    padding: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.write-text p {
    font-size: 1.2rem;
    font-family: 'Times New Roman', Times, serif;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 1rem;
}

.custom-react-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
}

.btn-1 {
    background-color: #240750;
    color: white;
    border-radius: 8px;
    border: none;
    outline: none;
    height: 3rem;
    width: 10rem;
}

.custom-curser {
    cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .write-text h1 {
        font-size: 1.2rem;
    }

    .write-text p {
        font-size: 0.9rem;
    }

    .btn-custom {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
}

@media (max-width: 576px) {
    .custom-file-container {
        padding: 10px;
        width: 20rem;
        height: 28rem;
    }

    .custum-inside-container {
        padding: 20px;
    }

    .write-text h1 {
        font-size: 1rem;
    }

    .write-text p {
        font-size: 0.8rem;
    }

    .btn-custom {
        font-size: 0.8rem;
        padding: 6px 12px;
    }

    .aspect-ratios {
        gap: 1rem;
        font-size: 0.7rem;
        font-weight: 200;
        color: white;
        margin: 5px;
        display: flex;
        width: 21rem;
        align-items: center;
    }

    .cropper {
        width:auto ;
    }
}